<div class="container-fluid">
    <div class="row mainContainer">
        <div class="col-md-6">
            <div class=" title1">
                <img src="../../assets/img/Rectangle 40097.png" class="me-2"><span id="straining">Full Stack Training</span>
            </div>
            <div class="row mt-4 align-items-center">
                <div class="col-auto">
                    <span><img class="img-polygon" src="../../assets/img/Polygon 7.png" alt="Polygon"></span>
                </div>
                <div class="col">
                    <span class="poly-text">
                        Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida ullamcorper amet.
                    </span>
                </div>
            </div>
            
            <div class="row mt-3 align-items-center">
                <div class="col-auto">
                    <span><img class="img-polygon" src="../../assets/img/Polygon 7.png" alt="Polygon"></span>
                </div>
                <div class="col">
                    <span class="poly-text">
                        Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida ullamcorper amet.
                    </span>
                </div>
            </div>
            
            <div class="row mt-3 align-items-center">
                <div class="col-auto">
                    <span><img class="img-polygon" src="../../assets/img/Polygon 7.png" alt="Polygon"></span>
                </div>
                <div class="col">
                    <span class="poly-text">
                        Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida ullamcorper amet.
                    </span>
                </div>
            </div>

            <div class="button-container">
                <button class="btn btn-get-trained mx-1" data-toggle="modal" data-target="#exampleModal" type="submit">Get Trained</button>
                <button class="btn btn-our-course mx-1" data-toggle="modal" data-target="#exampleModal" type="submit">Our Courses</button>            
            </div>
            
            
            
            <!-- <div class="row mt-3">
                <div class="col-md-3 d-flex justify-content-start">
                    <button class="btn btn-get-trained mx-1" data-toggle="modal" data-target="#exampleModal" type="submit">Get Trained</button>
                </div>
                <div class="col-md-9 d-flex justify-content-start">
                    <button class="btn btn-our-course mx-1" data-toggle="modal" data-target="#exampleModal" type="submit">Our Courses</button>
                </div>
            </div> -->

             </div>
        <div class="col-md-6">
            <img class="img-fluid mt-2"src="../../../assets/img/cou-images/full stack image.png" alt="Main Image" width="550px" id="salesforce">
        </div>
    </div>
</div>

<div class="row" style=" height: 100%; background: #F5FAFF;">
    <div class="row mt-5">
        <span class="courses">Full Stack COURES</span>
        <div class="row" style="justify-content: center;">
            <span style="text-align: center;width: 70px;
                height: 4px;
                flex-shrink: 0; background: linear-gradient(90deg, #224A79 0%, #EFA1A8 100%); z-index: 2;"></span>
        </div>
        <span class="curriculum">CURRICULUM</span>
    </div>
    <app-faq></app-faq>
    </div>

<div class="row platforms">
    <div class="row mt-5">
        <span class="platforms">AND PLATFORMS</span>
        <div class="row" style="justify-content: center;">
            <span style="text-align: center;width: 70px;
                height: 4px;
                flex-shrink: 0; background: linear-gradient(90deg, #224A79 0%, #EFA1A8 100%); z-index: 2;"></span>
        </div>
        <span class="tools">TOOLS</span>
    </div>

    <div class="row mt-5 text-center">
        <div class="col-md-1"></div>
        <div class="col-md-2 col-4"><img src=".././../assets/img/image 62.png" style="max-width: 100%;"></div>
        <div class="col-md-2 col-4"><img src="../../assets/img/image 64.png" style="max-width: 100%;"></div>
        <div class="col-md-2 col-4"><img src=".././../assets/img/salesforce.png" style="max-width: 100%;"></div>
        <div class="col-md-2 col-4"><img src=".././../assets/img/fa6-brands_square-x-twitter.png" style="max-width: 100%;"></div>
        <div class="col-md-2 col-4"><img src=".././../assets/img/image 78.png" style="max-width: 100%;"></div>
        <div class="col-md-1"></div>
    </div>
    

</div>




<div class="row proj"  style="background: #F5F5F5; height: 100%; flex-shrink: 0;">

    <div class="row mt-5">
        <span style="text-align: center; font-size: 25px; font-weight: bold;">Full Stack Real Time Projects</span>
        <div class="row" style="justify-content: center;">
            <span style="text-align: center;width: 70px;
                    height: 4px;
                    flex-shrink: 0; background: linear-gradient(90deg, #224A79 0%, #EFA1A8 100%) "></span>
        </div>
    
    </div>
    <div class="p-5">
        <div class="row mt-3 p-5">
            <span class="p-5" style="width:100%;background-color:white">
                <span class="p-3" style="width:50%;">
                    <div class="row">
                        <div class="col-md-5"></div>
                        <div class="col-md-7">
                            <div class="row-mt-1" id="project"> project-1 </div>
                    <div class="row-mt-1 mb-2"><b>Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida </b></div>
                    Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                    ullamcorper amet. Placerat aliquam
                    eleifend proin iaculis sapien
                    tincidunt nascetur. Nulla tempus eu purus in turpis.Placerat aliquam eleifend proin iaculis sapien
                    tincidunt nascetur.
                    Nulla tempus eu purus in turpis.
                        </div>
                        
                    </div>
                    
                </span>
            </span>

        </div>
    
        <div class="row  p-5">
            <span class="p-5" style="width:100%;background-color:white">
                <span class="p-3" style="width:50%;">
                    <div class="row">
                        
                        <div class="col-md-7">
                            <div class="row-mt-1" id="project"> project-2 </div>
                    <div class="row-mt-1 mb-2"><b>Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida </b></div>
                    Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                    ullamcorper amet. Placerat aliquam
                    eleifend proin iaculis sapien
                    tincidunt nascetur. Nulla tempus eu purus in turpis.Placerat aliquam eleifend proin iaculis sapien
                    tincidunt nascetur.
                    Nulla tempus eu purus in turpis.
                        </div>
                        <div class="col-md-5"></div>
                        
                    </div>
                    
                </span>
            </span>

        </div>
    
        <div class="row  p-5">
            <span class="p-5" style="width:100%;background-color:white">
                <span class="p-3" style="width:50%;">
                    <div class="row">
                        <div class="col-md-5"></div>
                        <div class="col-md-7">
                            <div class="row-mt-1" id="project"> project-3 </div>
                    <div class="row-mt-1 mb-2"><b>Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida </b></div>
                    Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                    ullamcorper amet. Placerat aliquam
                    eleifend proin iaculis sapien
                    tincidunt nascetur. Nulla tempus eu purus in turpis.Placerat aliquam eleifend proin iaculis sapien
                    tincidunt nascetur.
                    Nulla tempus eu purus in turpis.
                        </div>
                        
                    </div>
                    
                </span>
            </span>

        </div>
    </div>

    
</div>

<div class="row mt-5  batchschedual">
    <div style="text-align: center;" class="fw-bold">Full Stack Upcoming Batch Schedule</div>
    <div class="row" style="justify-content: center;">
        <span style="text-align: center;width: 70px;
                height: 4px;
                flex-shrink: 0; background: linear-gradient(90deg, #224A79 0%, #EFA1A8 100%) " class="mt-2"></span>
    </div>

</div>
<div class="container mt-5">
    <div class="row justify-content-center">
        <div class="col p-2">
            <div class="card" style="width:100%;">
                <!-- ... Content for Card 1 ... -->
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"><b>Week Day Batches </b> <br>(mon-fri) </li>
                    <li class="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M8 14C7.71667 14 7.479 13.904 7.287 13.712C7.09567 13.5207 7 13.2833 7 13C7 12.7167 7.09567 12.479 7.287 12.287C7.479 12.0957 7.71667 12 8 12C8.28333 12 8.521 12.0957 8.713 12.287C8.90433 12.479 9 12.7167 9 13C9 13.2833 8.90433 13.5207 8.713 13.712C8.521 13.904 8.28333 14 8 14ZM12 14C11.7167 14 11.4793 13.904 11.288 13.712C11.096 13.5207 11 13.2833 11 13C11 12.7167 11.096 12.479 11.288 12.287C11.4793 12.0957 11.7167 12 12 12C12.2833 12 12.521 12.0957 12.713 12.287C12.9043 12.479 13 12.7167 13 13C13 13.2833 12.9043 13.5207 12.713 13.712C12.521 13.904 12.2833 14 12 14ZM16 14C15.7167 14 15.4793 13.904 15.288 13.712C15.096 13.5207 15 13.2833 15 13C15 12.7167 15.096 12.479 15.288 12.287C15.4793 12.0957 15.7167 12 16 12C16.2833 12 16.5207 12.0957 16.712 12.287C16.904 12.479 17 12.7167 17 13C17 13.2833 16.904 13.5207 16.712 13.712C16.5207 13.904 16.2833 14 16 14ZM5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2.975C6 2.69167 6.09567 2.45833 6.287 2.275C6.479 2.09167 6.71667 2 7 2C7.28333 2 7.521 2.09567 7.713 2.287C7.90433 2.479 8 2.71667 8 3V4H16V2.975C16 2.69167 16.096 2.45833 16.288 2.275C16.4793 2.09167 16.7167 2 17 2C17.2833 2 17.5207 2.09567 17.712 2.287C17.904 2.479 18 2.71667 18 3V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V20C21 20.55 20.8043 21.021 20.413 21.413C20.021 21.8043 19.55 22 19 22H5ZM5 20H19V10H5V20Z" fill="#262626"/>
                    </svg> <b>16-Jan-2023</b><br>Monday</li>
                    <li class="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM17 13H11V7H12.5V11.5H17V13Z" fill="#262626"/>
                    </svg><b>8 AM (IST)</b> <br>1hr-1:30hr/per session</li>
                    <li class="list-group-item"><button class="btn-shed" data-toggle="modal" data-target="#exampleModal"> Our courses</button></li>
                  </ul>
            </div>
        </div>
        <div class="col p-2">
            <div class="card" style="width: 100%;">
                <!-- ... Content for Card 2 ... -->
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"><b>Week Day Batches </b> <br>(mon-fri) </li>
                    <li class="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M8 14C7.71667 14 7.479 13.904 7.287 13.712C7.09567 13.5207 7 13.2833 7 13C7 12.7167 7.09567 12.479 7.287 12.287C7.479 12.0957 7.71667 12 8 12C8.28333 12 8.521 12.0957 8.713 12.287C8.90433 12.479 9 12.7167 9 13C9 13.2833 8.90433 13.5207 8.713 13.712C8.521 13.904 8.28333 14 8 14ZM12 14C11.7167 14 11.4793 13.904 11.288 13.712C11.096 13.5207 11 13.2833 11 13C11 12.7167 11.096 12.479 11.288 12.287C11.4793 12.0957 11.7167 12 12 12C12.2833 12 12.521 12.0957 12.713 12.287C12.9043 12.479 13 12.7167 13 13C13 13.2833 12.9043 13.5207 12.713 13.712C12.521 13.904 12.2833 14 12 14ZM16 14C15.7167 14 15.4793 13.904 15.288 13.712C15.096 13.5207 15 13.2833 15 13C15 12.7167 15.096 12.479 15.288 12.287C15.4793 12.0957 15.7167 12 16 12C16.2833 12 16.5207 12.0957 16.712 12.287C16.904 12.479 17 12.7167 17 13C17 13.2833 16.904 13.5207 16.712 13.712C16.5207 13.904 16.2833 14 16 14ZM5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2.975C6 2.69167 6.09567 2.45833 6.287 2.275C6.479 2.09167 6.71667 2 7 2C7.28333 2 7.521 2.09567 7.713 2.287C7.90433 2.479 8 2.71667 8 3V4H16V2.975C16 2.69167 16.096 2.45833 16.288 2.275C16.4793 2.09167 16.7167 2 17 2C17.2833 2 17.5207 2.09567 17.712 2.287C17.904 2.479 18 2.71667 18 3V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V20C21 20.55 20.8043 21.021 20.413 21.413C20.021 21.8043 19.55 22 19 22H5ZM5 20H19V10H5V20Z" fill="#262626"/>
                    </svg> <b>16-Jan-2023</b><br>Monday</li>
                    <li class="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM17 13H11V7H12.5V11.5H17V13Z" fill="#262626"/>
                    </svg><b>8 AM (IST)</b> <br>1hr-1:30hr/per session</li>
                    <li class="list-group-item"><button class="btn-shed" data-toggle="modal" data-target="#exampleModal"> Our courses</button></li>
                  </ul>
            </div>
        </div>
        <div class="col p-2">
            <div class="card" style="width: 100%;">
                <!-- ... Content for Card 3 ... -->
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"><b>Week Day Batches </b> <br>(mon-fri) </li>
                    <li class="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M8 14C7.71667 14 7.479 13.904 7.287 13.712C7.09567 13.5207 7 13.2833 7 13C7 12.7167 7.09567 12.479 7.287 12.287C7.479 12.0957 7.71667 12 8 12C8.28333 12 8.521 12.0957 8.713 12.287C8.90433 12.479 9 12.7167 9 13C9 13.2833 8.90433 13.5207 8.713 13.712C8.521 13.904 8.28333 14 8 14ZM12 14C11.7167 14 11.4793 13.904 11.288 13.712C11.096 13.5207 11 13.2833 11 13C11 12.7167 11.096 12.479 11.288 12.287C11.4793 12.0957 11.7167 12 12 12C12.2833 12 12.521 12.0957 12.713 12.287C12.9043 12.479 13 12.7167 13 13C13 13.2833 12.9043 13.5207 12.713 13.712C12.521 13.904 12.2833 14 12 14ZM16 14C15.7167 14 15.4793 13.904 15.288 13.712C15.096 13.5207 15 13.2833 15 13C15 12.7167 15.096 12.479 15.288 12.287C15.4793 12.0957 15.7167 12 16 12C16.2833 12 16.5207 12.0957 16.712 12.287C16.904 12.479 17 12.7167 17 13C17 13.2833 16.904 13.5207 16.712 13.712C16.5207 13.904 16.2833 14 16 14ZM5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2.975C6 2.69167 6.09567 2.45833 6.287 2.275C6.479 2.09167 6.71667 2 7 2C7.28333 2 7.521 2.09567 7.713 2.287C7.90433 2.479 8 2.71667 8 3V4H16V2.975C16 2.69167 16.096 2.45833 16.288 2.275C16.4793 2.09167 16.7167 2 17 2C17.2833 2 17.5207 2.09567 17.712 2.287C17.904 2.479 18 2.71667 18 3V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V20C21 20.55 20.8043 21.021 20.413 21.413C20.021 21.8043 19.55 22 19 22H5ZM5 20H19V10H5V20Z" fill="#262626"/>
                    </svg> <b>16-Jan-2023</b><br>Monday</li>
                    <li class="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM17 13H11V7H12.5V11.5H17V13Z" fill="#262626"/>
                    </svg><b>8 AM (IST)</b> <br>1hr-1:30hr/per session</li>
                    <li class="list-group-item"><button class="btn-shed" data-toggle="modal" data-target="#exampleModal"> Our courses</button></li>
                  </ul>
            </div>
        </div>
        <div class="col p-2">
            <div class="card" style="width: 100%;">
                <!-- ... Content for Card 4 ... -->
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"><b>Week Day Batches </b> <br>(mon-fri) </li>
                    <li class="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M8 14C7.71667 14 7.479 13.904 7.287 13.712C7.09567 13.5207 7 13.2833 7 13C7 12.7167 7.09567 12.479 7.287 12.287C7.479 12.0957 7.71667 12 8 12C8.28333 12 8.521 12.0957 8.713 12.287C8.90433 12.479 9 12.7167 9 13C9 13.2833 8.90433 13.5207 8.713 13.712C8.521 13.904 8.28333 14 8 14ZM12 14C11.7167 14 11.4793 13.904 11.288 13.712C11.096 13.5207 11 13.2833 11 13C11 12.7167 11.096 12.479 11.288 12.287C11.4793 12.0957 11.7167 12 12 12C12.2833 12 12.521 12.0957 12.713 12.287C12.9043 12.479 13 12.7167 13 13C13 13.2833 12.9043 13.5207 12.713 13.712C12.521 13.904 12.2833 14 12 14ZM16 14C15.7167 14 15.4793 13.904 15.288 13.712C15.096 13.5207 15 13.2833 15 13C15 12.7167 15.096 12.479 15.288 12.287C15.4793 12.0957 15.7167 12 16 12C16.2833 12 16.5207 12.0957 16.712 12.287C16.904 12.479 17 12.7167 17 13C17 13.2833 16.904 13.5207 16.712 13.712C16.5207 13.904 16.2833 14 16 14ZM5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2.975C6 2.69167 6.09567 2.45833 6.287 2.275C6.479 2.09167 6.71667 2 7 2C7.28333 2 7.521 2.09567 7.713 2.287C7.90433 2.479 8 2.71667 8 3V4H16V2.975C16 2.69167 16.096 2.45833 16.288 2.275C16.4793 2.09167 16.7167 2 17 2C17.2833 2 17.5207 2.09567 17.712 2.287C17.904 2.479 18 2.71667 18 3V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V20C21 20.55 20.8043 21.021 20.413 21.413C20.021 21.8043 19.55 22 19 22H5ZM5 20H19V10H5V20Z" fill="#262626"/>
                    </svg> <b>16-Jan-2023</b><br>Monday</li>
                    <li class="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM17 13H11V7H12.5V11.5H17V13Z" fill="#262626"/>
                    </svg><b>8 AM (IST)</b> <br>1hr-1:30hr/per session</li>
                    <li class="list-group-item"><button class="btn-shed" data-toggle="modal" data-target="#exampleModal"> Our courses</button></li>
                  </ul>
            </div>
        </div>
        <div class="col p-3">
            <img src="../../assets/img/undraw_schedule_re_2vro (1) 1.png" style="width: 213px;
            height: 208px;
            flex-shrink: 0;">
        </div>
    </div>
</div>


<app-lets-create></app-lets-create>

<app-more-deatils></app-more-deatils>


<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" style="width: 100%; height: 100%; background: #1E4878; border-top-left-radius: 10px; border-top-right-radius: 4px">
          <h5 class="modal-title" id="exampleModalLabel" style="color: white; font-size: 22px; font-family: Montserrat; font-weight: 500; word-wrap: break-word; align-items: center;">Get Trained</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" style="color: white;">&times;</span>
          </button>
        </div>
        <form>
           <div class=" mt-3 form-group" style="margin-left: 50px;margin-right: 50px;">
              <label for="Name" class="col-form-label">Name</label>
              <input type="text" class="form-control" id="message-text" placeholder="Enter your name" style="width: 100%; background: #F3F7F9">
            </div>
            <div class=" form-group" style="margin-left: 50px;margin-right: 50px;">
              <label for="Name" class="col-form-label">Email</label>
              <input type="text" class="form-control" id="message-text" placeholder="Enter your Email" style="width: 100%; background: #F3F7F9">
            </div>
            <div class=" form-group" style="margin-left: 50px;margin-right: 50px;">
              <label for="Name" class="col-form-label">Phone</label>
              <input type="text" class="form-control" id="message-text" placeholder="Enter your phone number" style="width: 100%; background: #F3F7F9">
            </div>
            <div  style="color: #3D434F; font-size: 16px; font-family: Montserrat; font-weight: 500; word-wrap: break-word; margin-left: 50PX;">Our Course Adviser will reach you soon. <img src="../../../assets/img/noto_smiling-face.png"> </div>
        </form>
        <div  class=" mt-4 mb-4 submit-btn" style="font-size: 20px;
        font-family: Montserrat;
        font-weight: 500;
        word-wrap: break-word;">
          <button  type="button" class="btn btn-secondary" data-dismiss="modal" style="width: fit-content; margin-left: 50px;" routerLink="thanksforsubmitting">Submit</button>
        </div>
          
        
      </div>
    </div>
  </div>
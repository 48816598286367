import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  constructor(private router : Router){

  }

  navigateTo(type:any){
    console.log(type);
    localStorage.setItem('type',type);
    if(type === 'salesforce')
    {
      this.router.navigate(['salesforce'])
    }
    else if(type === 'Python')
    {
      this.router.navigate(['Python'])

    }

    else if(type === 'Informatica-cloud')
  {
    this.router.navigate(['Informatica-cloud'])

  }
  else if(type === 'ServiceNow')
  {
    this.router.navigate(['ServiceNow'])

  }

  else if(type === 'full-stack')
  {
    this.router.navigate(['full-stack'])

  }

  else if(type === 'devops')
  {
    this.router.navigate(['devops'])

  }
  else if(type === 'job-ready-full-stack-java')
  {
    this.router.navigate(['job-ready-full-stack-java'])
  }
  else if(type === 'job-ready-full-stack-testing')
  {
    this.router.navigate(['job-ready-full-stack-testing'])
  }

  }

  toggleMenu() {
    const menuToggle = document.getElementById('menu-toggle') as HTMLInputElement;
    if (menuToggle) {
      menuToggle.checked = false;  // Uncheck the menu toggle to hide the menu
    }
  }

  
}



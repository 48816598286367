<div class="container-fluid mt-3 mb-3">
    <div class="row text-center text-md-left">
        <div class="col-md-3 col-12 p-3 text-center">
            <a routerLink="">
                <img src="../../../assets/img/Group 6200.png" alt="Learn Digital Logo" class="img-fluid" width="200px">
            </a>
            <div class="follow-us mt-3 mb-3">
                <p>Follow us on:</p>
                <div class="social-icons d-flex justify-content-center mt-3">
                    <span class="me-3">
                        <a href="https://www.facebook.com/share/1GFBJVpYH9/" target="_blank">
                            <img src="../../assets/img/social media/uim_facebook-f.png" alt="Facebook" class="img-fluid">
                        </a>
                    </span>
                    <span class="me-3">
                        <a href="https://www.instagram.com/varahidigi" target="_blank">
                            <img src="../../assets/img/social media/instagram (1).png" alt="Instagram" class="img-fluid">
                        </a>
                    </span>
                    <span class="me-3">
                        <a href="https://www.linkedin.com/company/varahi360/" target="_blank">
                            <i class="fa fa-linkedin"></i>
                        </a>
                    </span>
                    <span>
                        <a href="https://www.youtube.com/@VarahiDigitallearning">
                            <i class="fa fa-youtube"></i>
                        </a>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-12 p-3">
            <div><a class="nav-link fw-normal" routerLink="">Home</a></div>
            <div><a class="nav-link fw-normal">Courses</a></div>
            <div><a class="nav-link fw-normal" routerLink="/carrer-support">Career Support</a></div>
        </div>
        <div class="col-md-3 col-12 p-3">
            <div><a class="nav-link fw-normal" routerLink="/salesforce">Salesforce</a></div>
            <div><a class="nav-link fw-normal" routerLink="/Python">Python</a></div>
            <div><a class="nav-link fw-normal" routerLink="/ServiceNow">Servicenow</a></div>
            <div><a class="nav-link fw-normal" routerLink="/devops">Devops</a></div>
        </div>
        <div class="col-md-3 col-12 p-3 text-center text-md-left">
            <p>For more details:</p>
            <div class="contact-info mt-3">
                <img src="../../assets/img/mingcute_mail-send-fill.png" alt="Email Icon" class="me-2"> 
                <span>Email: </span><a href="mailto:info@varahi360.com" class="fw-normal">info@varahi360.com</a>
            </div>
            <div class="contact-info mt-3">
                <img src="../../assets/img/fa6-solid_phone.png" alt="Phone Icon" class="me-2"> 
                <a href="https://wa.me/+919490242868" class="fw-normal">+91 9490242868</a>
            </div>
        </div>
    </div>
</div>
<hr>
<footer class="bg-light text-center text-lg-start">
    <div class="text-center p-3" style="background-color:white; color: #303943;" id="foot">
        © 2024 All Rights Reserved By Varahi360. <br>Powered By <a href="https://savvynxt.com/contact.html" target="_blank">SavvyNXT Technologies Pvt Ltd</a>
    </div>
</footer>

<!-- <div class="maincontainer " style="height: 100%;background: #F5FAFF;">
<div *ngIf="type == 'salesforce'" class="container mt-5 mb-3">
    <h2>Frequently Asked Questions SalesForce</h2>
    <div class="accordion" id="faqAccordion">
      <div *ngFor="let question of faqData" class="card">
        <div class="card-header" id="heading{{question.id}}">
          <h5 class="mb-0">
            <button
              class="btn btn-link"
              data-toggle="collapse"
              [attr.data-target]="'#collapse' + question.id"
              aria-expanded="true"
              [attr.aria-controls]="'collapse' + question.id"
            >
              {{ question.question }}
            </button>
          </h5>
        </div>
        <div
          [attr.id]="'collapse' + question.id"
          class="collapse"
          [ngClass]="{ show: question.id === 1 }"
          data-parent="#faqAccordion"
        >
          <div class="card-body">
            {{ question.answer }}
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="type == 'Python'" class="container mt-5">
    <h2>Frequently Asked Questions Python</h2>
    <div class="accordion" id="faqAccordion">
      <div *ngFor="let question of faqData" class="card">
        <div class="card-header" id="heading{{question.id}}">
          <h5 class="mb-0">
            <button
              class="btn btn-link"
              data-toggle="collapse"
              [attr.data-target]="'#collapse' + question.id"
              aria-expanded="true"
              [attr.aria-controls]="'collapse' + question.id"
            >
              {{ question.question }}
            </button>
          </h5>
        </div>
        <div
          [attr.id]="'collapse' + question.id"
          class="collapse"
          [ngClass]="{ show: question.id === 1 }"
          data-parent="#faqAccordion"
        >
          <div class="card-body">
            {{ question.answer }}
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="type == ''" class="container mt-5">
    <h2>Frequently Asked Questions</h2>
    <div class="accordion" id="faqAccordion">
      <div *ngFor="let question of faqData" class="card">
        <div class="card-header" id="heading{{question.id}}">
          <h5 class="mb-0">
            <button
              class="btn btn-link"
              data-toggle="collapse"
              [attr.data-target]="'#collapse' + question.id"
              aria-expanded="true"
              [attr.aria-controls]="'collapse' + question.id"
            >
              {{ question.question }}
            </button>
          </h5>
        </div>
        <div
          [attr.id]="'collapse' + question.id"
          class="collapse"
          [ngClass]="{ show: question.id === 1 }"
          data-parent="#faqAccordion"
        >
          <div class="card-body">
            {{ question.answer }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="type == ''" class="container mt-5">
    <h2>Frequently Asked Questions</h2>
    <div class="accordion" id="faqAccordion">
      <div *ngFor="let question of faqData" class="card">
        <div class="card-header" id="heading{{question.id}}">
          <h5 class="mb-0">
            <button
              class="btn btn-link"
              data-toggle="collapse"
              [attr.data-target]="'#collapse' + question.id"
              aria-expanded="true"
              [attr.aria-controls]="'collapse' + question.id"
            >
              {{ question.question }}
            </button>
          </h5>
        </div>
        <div
          [attr.id]="'collapse' + question.id"
          class="collapse"
          [ngClass]="{ show: question.id === 1 }"
          data-parent="#faqAccordion"
        >
          <div class="card-body">
            {{ question.answer }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->



<!--   
    <div class="row justify-content-center mt-5">
      <div class="col-11 col-xl-10 mt-3">
        <div class="d-flex align-items-center mb-1" style="height: 100%; background: #204978; color: white; padding: 10px  500px;">
          <i class="bi bi-person-gear me-3 lh-1 display-5"></i>
           <h3 class="m-0">SalesForce course</h3> 
         </div>
      </div> 
      <div class="col-11 col-xl-10 mt-3">
        <div class="accordion accordion-flush" id="faqAccount">
          
            <h2 class="accordion-header" id="faqAccountHeading1">
              
                
                

                  <div class="row" style=" width:100%;height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px">
                    <div class="col-md-1" style="background: #204978; width: 50px; height:50px;">
                      <div style="align-items: center; color:#CFDDED; font-size: 24px; font-family: Montserrat; font-weight: 600; word-wrap: break-word;background: #204978 ;">1</div>
  
                    </div>

                    <div class="col-md-11">
                      <div style="width: 100%; color: #262626; font-size: 18px; font-family: Montserrat; font-weight: 500; word-wrap: break-word">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida 
                        <button class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse1" aria-expanded="false" aria-controls="faqAccountCollapse1"></button>
                      </div>
  
                    </div> 

                  </div>
            </h2>
            <div id="faqAccountCollapse1" class="accordion-collapse collapse" aria-labelledby="faqAccountHeading1">
              <div class="accordion-body">
                <p>Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</p>
              </div>
            </div>
          
         
          
         
          
        </div>
      </div>
    </div> -->

    <div *ngIf="type == 'salesforce'" class="container mb-5" style="width: 90%">
        <div class="row mt-5" style=" background-color: #204978; color: white; padding: 10px ; justify-content: center;">
          <span class="faq-header" style="display: flex;align-items: center;justify-content: center;"> Salesforce Admin</span></div>
  
          
          <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
            <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 1</div></div>
          <div class="col-md-11">
           
           <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                  </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                </div>
              </div>
             
            </div>
          </div></div>
  
  
          <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
            <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 2</div></div>
          <div class="col-md-11">
           
           <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                  </button>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                </div>
              </div>
             
            </div>
          </div></div>
      
          <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
            <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 3</div></div>
          <div class="col-md-11">
           
           <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingthree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsethree" aria-expanded="false" aria-controls="flush-collapsethree">
                    Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                  </button>
                </h2>
                <div id="flush-collapsethree" class="accordion-collapse collapse" aria-labelledby="flush-headingthree" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                </div>
              </div>
             
            </div>
          </div></div>
      
          <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
            <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 4</div></div>
          <div class="col-md-11">
           
           <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingfour">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
                    Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                  </button>
                </h2>
                <div id="flush-collapsefour" class="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                </div>
              </div>
             
            </div>
          </div></div>
      
      
          <div class="row mt-3" style=" background-color: #204978; color: white; padding: 10px ; justify-content: center;">
            <span class="faq-header" style="display: flex;align-items: center;justify-content: center;">Salesforce Developer </span></div>
      
            <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
              <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 1</div></div>
            <div class="col-md-11">
             
             <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingfive">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                      Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                    </button>
                  </h2>
                  <div id="flush-collapsefive" class="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                  </div>
                </div>
               
              </div>
            </div></div>
  
  
            <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
              <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 3</div></div>
            <div class="col-md-11">
             
             <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingsix">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                      Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                    </button>
                  </h2>
                  <div id="flush-collapsesix" class="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                  </div>
                </div>
               
              </div>
            </div></div>
      
            <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
              <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 2</div></div>
            <div class="col-md-11">
             
             <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingseven">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseseven" aria-expanded="false" aria-controls="flush-collapseseven">
                      Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                    </button>
                  </h2>
                  <div id="flush-collapseseven" class="accordion-collapse collapse" aria-labelledby="flush-headingseven" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                  </div>
                </div>
               
              </div>
            </div></div>
      
            <div class="row mt-3" style=" background-color: #204978; color: white; padding: 10px ; justify-content: center;">
              <span class="faq-header" style="display: flex;align-items: center;justify-content: center;"> Salesforce LWC </span></div>
      
              <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 1</div> </div>
              <div class="col-md-11">
               
               <div class="accordion accordion-flush" id="accordionFlushExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingeight">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseeight" aria-expanded="false" aria-controls="flush-collapseeight">
                        Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                      </button>
                    </h2>
                    <div id="flush-collapseeight" class="accordion-collapse collapse" aria-labelledby="flush-headingeight" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                    </div>
                  </div>
                 
                </div>
              </div></div>
              <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 2</div></div>
              <div class="col-md-11">
               
               <div class="accordion accordion-flush" id="accordionFlushExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingnine">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsenine" aria-expanded="false" aria-controls="flush-collapsenine">
                        Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                      </button>
                    </h2>
                    <div id="flush-collapsenine" class="accordion-collapse collapse" aria-labelledby="flush-headingnine" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                    </div>
                  </div>
                 
                </div>
              </div></div>
      
              <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 3</div></div>
              <div class="col-md-11">
               
               <div class="accordion accordion-flush" id="accordionFlushExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingten">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseten" aria-expanded="false" aria-controls="flush-collapseten">
                        Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                      </button>
                    </h2>
                    <div id="flush-collapseten" class="accordion-collapse collapse" aria-labelledby="flush-heading" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                    </div>
                  </div>
                 
                </div>
              </div></div>
    
            
  
  
     
  </div>
  
  
  
  
  <div *ngIf="type == 'Python'" class="container mb-5" style="width: 90%">
    <div class="row mt-5" style=" background-color: #204978; color: white; padding: 10px ; justify-content: center;">
      <span class="faq-header" style="display: flex;align-items: center;justify-content: center;"> PHTHON Developer</span></div>
  
     
      <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
        <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 1</div></div>
      <div class="col-md-11">
       
       <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
              </button>
            </h2>
            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
            </div>
          </div>
         
        </div>
      </div></div>
  
  
      <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
        <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 2</div></div>
      <div class="col-md-11">
       
       <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
              </button>
            </h2>
            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
            </div>
          </div>
         
        </div>
      </div></div>
  
      <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
        <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 3</div></div>
      <div class="col-md-11">
       
       <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingthree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsethree" aria-expanded="false" aria-controls="flush-collapsethree">
                Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
              </button>
            </h2>
            <div id="flush-collapsethree" class="accordion-collapse collapse" aria-labelledby="flush-headingthree" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
            </div>
          </div>
         
        </div>
      </div></div>
  
      <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
        <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 4</div></div>
      <div class="col-md-11">
       
       <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingfour">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
                Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
              </button>
            </h2>
            <div id="flush-collapsefour" class="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
            </div>
          </div>
         
        </div>
      </div></div>
  
  
      <div class="row mt-3" style=" background-color: #204978; color: white; padding: 10px ; justify-content: center;">
        <span class="faq-header" style="display: flex;align-items: center;justify-content: center;">Data Analyst</span></div>
  
        <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
          <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 1</div></div>
        <div class="col-md-11">
         
         <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingfive">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                  Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                </button>
              </h2>
              <div id="flush-collapsefive" class="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
              </div>
            </div>
           
          </div>
        </div></div>
  
  
        <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
          <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 3</div></div>
        <div class="col-md-11">
         
         <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingsix">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                  Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                </button>
              </h2>
              <div id="flush-collapsesix" class="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
              </div>
            </div>
           
          </div>
        </div></div>
  
        <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
          <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 2</div></div>
        <div class="col-md-11">
         
         <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingseven">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseseven" aria-expanded="false" aria-controls="flush-collapseseven">
                  Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                </button>
              </h2>
              <div id="flush-collapseseven" class="accordion-collapse collapse" aria-labelledby="flush-headingseven" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
              </div>
            </div>
           
          </div>
        </div></div>
  
        <div class="row mt-3" style=" background-color: #204978; color: white; padding: 10px ; justify-content: center;">
          <span class="faq-header" style="display: flex;align-items: center;justify-content: center;"> Software Developer </span></div>
  
          <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
            <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 1</div> </div>
          <div class="col-md-11">
           
           <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingeight">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseeight" aria-expanded="false" aria-controls="flush-collapseeight">
                    Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                  </button>
                </h2>
                <div id="flush-collapseeight" class="accordion-collapse collapse" aria-labelledby="flush-headingeight" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                </div>
              </div>
             
            </div>
          </div></div>
          <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
            <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 2</div></div>
          <div class="col-md-11">
           
           <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingnine">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsenine" aria-expanded="false" aria-controls="flush-collapsenine">
                    Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                  </button>
                </h2>
                <div id="flush-collapsenine" class="accordion-collapse collapse" aria-labelledby="flush-headingnine" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                </div>
              </div>
             
            </div>
          </div></div>
  
          <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
            <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 3</div></div>
          <div class="col-md-11">
           
           <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingten">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseten" aria-expanded="false" aria-controls="flush-collapseten">
                    Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                  </button>
                </h2>
                <div id="flush-collapseten" class="accordion-collapse collapse" aria-labelledby="flush-heading" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                </div>
              </div>
             
            </div>
          </div></div>
  
        </div>
  
  
  
        <div *ngIf="type == 'Informatica-cloud'" class="container mb-5" style="width: 90%">
          <div class="row mt-5" style=" background-color: #204978; color: white; padding: 10px ; justify-content: center;">
            <span class="faq-header" style="display: flex;align-items: center;justify-content: center;"> Cloud</span></div>
        
            
            <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
              <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 1</div></div>
            <div class="col-md-11">
             
             <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                    </button>
                  </h2>
                  <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                  </div>
                </div>
               
              </div>
            </div></div>
  
  
            <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
              <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 2</div></div>
            <div class="col-md-11">
             
             <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                  </div>
                </div>
               
              </div>
            </div></div>
        
            <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
              <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 3</div></div>
            <div class="col-md-11">
             
             <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingthree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsethree" aria-expanded="false" aria-controls="flush-collapsethree">
                      Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                    </button>
                  </h2>
                  <div id="flush-collapsethree" class="accordion-collapse collapse" aria-labelledby="flush-headingthree" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                  </div>
                </div>
               
              </div>
            </div></div>
        
            <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
              <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 4</div></div>
            <div class="col-md-11">
             
             <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingfour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
                      Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                    </button>
                  </h2>
                  <div id="flush-collapsefour" class="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                  </div>
                </div>
               
              </div>
            </div></div>
        
        
            <div class="row mt-3" style=" background-color: #204978; color: white; padding: 10px ; justify-content: center;">
              <span class="faq-header" style="display: flex;align-items: center;justify-content: center;">Cloud Engineer</span></div>
        
              <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 1</div></div>
              <div class="col-md-11">
               
               <div class="accordion accordion-flush" id="accordionFlushExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingfive">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                        Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                      </button>
                    </h2>
                    <div id="flush-collapsefive" class="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                    </div>
                  </div>
                 
                </div>
              </div></div>
  
  
              <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 3</div></div>
              <div class="col-md-11">
               
               <div class="accordion accordion-flush" id="accordionFlushExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingsix">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                        Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                      </button>
                    </h2>
                    <div id="flush-collapsesix" class="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                    </div>
                  </div>
                 
                </div>
              </div></div>
        
              <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 2</div></div>
              <div class="col-md-11">
               
               <div class="accordion accordion-flush" id="accordionFlushExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingseven">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseseven" aria-expanded="false" aria-controls="flush-collapseseven">
                        Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                      </button>
                    </h2>
                    <div id="flush-collapseseven" class="accordion-collapse collapse" aria-labelledby="flush-headingseven" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                    </div>
                  </div>
                 
                </div>
              </div></div>
        
              <div class="row mt-3" style=" background-color: #204978; color: white; padding: 10px ; justify-content: center;">
                <span class="faq-header" style="display: flex;align-items: center;justify-content: center;"> Information cloud </span></div>
        
                <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                  <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 1</div> </div>
                <div class="col-md-11">
                 
                 <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingeight">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseeight" aria-expanded="false" aria-controls="flush-collapseeight">
                          Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                        </button>
                      </h2>
                      <div id="flush-collapseeight" class="accordion-collapse collapse" aria-labelledby="flush-headingeight" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                      </div>
                    </div>
                   
                  </div>
                </div></div>
                <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                  <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 2</div></div>
                <div class="col-md-11">
                 
                 <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingnine">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsenine" aria-expanded="false" aria-controls="flush-collapsenine">
                          Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                        </button>
                      </h2>
                      <div id="flush-collapsenine" class="accordion-collapse collapse" aria-labelledby="flush-headingnine" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                      </div>
                    </div>
                   
                  </div>
                </div></div>
        
                <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                  <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 3</div></div>
                <div class="col-md-11">
                 
                 <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingten">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseten" aria-expanded="false" aria-controls="flush-collapseten">
                          Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                        </button>
                      </h2>
                      <div id="flush-collapseten" class="accordion-collapse collapse" aria-labelledby="flush-heading" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                      </div>
                    </div>
                   
                  </div>
                </div></div>
        
              </div>
  
  
  
  
              <div *ngIf="type == 'devops'" class="container mb-5" style="width: 90%">
                <div class="row mt-5" style=" background-color: #204978; color: white; padding: 10px ; justify-content: center;">
                  <span class="faq-header" style="display: flex;align-items: center;justify-content: center;">System Engineer</span></div>
              
                 
                  <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                    <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 1</div></div>
                  <div class="col-md-11">
                   
                   <div class="accordion accordion-flush" id="accordionFlushExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                          </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                          <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                        </div>
                      </div>
                     
                    </div>
                  </div></div>
  
  
                  <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                    <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 2</div></div>
                  <div class="col-md-11">
                   
                   <div class="accordion accordion-flush" id="accordionFlushExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingTwo">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                          </button>
                        </h2>
                        <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                          <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                        </div>
                      </div>
                     
                    </div>
                  </div></div>
              
                  <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                    <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 3</div></div>
                  <div class="col-md-11">
                   
                   <div class="accordion accordion-flush" id="accordionFlushExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingthree">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsethree" aria-expanded="false" aria-controls="flush-collapsethree">
                            Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                          </button>
                        </h2>
                        <div id="flush-collapsethree" class="accordion-collapse collapse" aria-labelledby="flush-headingthree" data-bs-parent="#accordionFlushExample">
                          <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                        </div>
                      </div>
                     
                    </div>
                  </div></div>
              
                  <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                    <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 4</div></div>
                  <div class="col-md-11">
                   
                   <div class="accordion accordion-flush" id="accordionFlushExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingfour">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
                            Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                          </button>
                        </h2>
                        <div id="flush-collapsefour" class="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                          <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                        </div>
                      </div>
                     
                    </div>
                  </div></div>
              
              
                  <div class="row mt-3" style=" background-color: #204978; color: white; padding: 10px ; justify-content: center;">
                    <span class="faq-header" style="display: flex;align-items: center;justify-content: center;">Devops Architect</span></div>
              
                    <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                      <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 1</div></div>
                    <div class="col-md-11">
                     
                     <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="flush-headingfive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                              Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                            </button>
                          </h2>
                          <div id="flush-collapsefive" class="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                          </div>
                        </div>
                       
                      </div>
                    </div></div>
  
  
                    <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                      <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 3</div></div>
                    <div class="col-md-11">
                     
                     <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="flush-headingsix">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                              Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                            </button>
                          </h2>
                          <div id="flush-collapsesix" class="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                          </div>
                        </div>
                       
                      </div>
                    </div></div>
              
                    <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                      <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 2</div></div>
                    <div class="col-md-11">
                     
                     <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="flush-headingseven">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseseven" aria-expanded="false" aria-controls="flush-collapseseven">
                              Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                            </button>
                          </h2>
                          <div id="flush-collapseseven" class="accordion-collapse collapse" aria-labelledby="flush-headingseven" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                          </div>
                        </div>
                       
                      </div>
                    </div></div>
              
                    <div class="row mt-3" style=" background-color: #204978; color: white; padding: 10px ; justify-content: center;">
                      <span class="faq-header" style="display: flex;align-items: center;justify-content: center;">Devops Automation Testing </span></div>
              
                      <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                        <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 1</div> </div>
                      <div class="col-md-11">
                       
                       <div class="accordion accordion-flush" id="accordionFlushExample">
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingeight">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseeight" aria-expanded="false" aria-controls="flush-collapseeight">
                                Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                              </button>
                            </h2>
                            <div id="flush-collapseeight" class="accordion-collapse collapse" aria-labelledby="flush-headingeight" data-bs-parent="#accordionFlushExample">
                              <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                            </div>
                          </div>
                         
                        </div>
                      </div></div>
                      <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                        <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 2</div></div>
                      <div class="col-md-11">
                       
                       <div class="accordion accordion-flush" id="accordionFlushExample">
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingnine">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsenine" aria-expanded="false" aria-controls="flush-collapsenine">
                                Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                              </button>
                            </h2>
                            <div id="flush-collapsenine" class="accordion-collapse collapse" aria-labelledby="flush-headingnine" data-bs-parent="#accordionFlushExample">
                              <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                            </div>
                          </div>
                         
                        </div>
                      </div></div>
              
                      <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                        <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 3</div></div>
                      <div class="col-md-11">
                       
                       <div class="accordion accordion-flush" id="accordionFlushExample">
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingten">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseten" aria-expanded="false" aria-controls="flush-collapseten">
                                Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                              </button>
                            </h2>
                            <div id="flush-collapseten" class="accordion-collapse collapse" aria-labelledby="flush-heading" data-bs-parent="#accordionFlushExample">
                              <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                            </div>
                          </div>
                         
                        </div>
                      </div></div>
              
                    </div>
  
  
  
                    <div *ngIf="type == 'ServiceNow'" class="container mb-5" style="width: 90%">
                      <div class="row header mt-5" style=" background-color: #204978; color: white; padding: 10px ; justify-content: center;">
                        <span class="faq-header" style="display: flex;align-items: center;justify-content: center;">System Enginner</span></div>
                    
                        <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                          <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 1</div></div>
                        <div class="col-md-11">
                         
                         <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                  Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                </button>
                              </h2>
                              <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                              </div>
                            </div>
                           
                          </div>
                        </div></div>
  
  
                        <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                          <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 2</div></div>
                        <div class="col-md-11">
                         
                         <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="flush-headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                  Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                </button>
                              </h2>
                              <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                              </div>
                            </div>
                           
                          </div>
                        </div></div>
                    
                        <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                          <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 3</div></div>
                        <div class="col-md-11">
                         
                         <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="flush-headingthree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsethree" aria-expanded="false" aria-controls="flush-collapsethree">
                                  Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                </button>
                              </h2>
                              <div id="flush-collapsethree" class="accordion-collapse collapse" aria-labelledby="flush-headingthree" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                              </div>
                            </div>
                           
                          </div>
                        </div></div>
                    
                        <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                          <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 4</div></div>
                        <div class="col-md-11">
                         
                         <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="flush-headingfour">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
                                  Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                </button>
                              </h2>
                              <div id="flush-collapsefour" class="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                              </div>
                            </div>
                           
                          </div>
                        </div></div>
                    
                    
                        <div class="row mt-3" style=" background-color: #204978; color: white; padding: 10px ; justify-content: center;">
                          <span class="faq-header" style="display: flex;align-items: center;justify-content: center;">Devops Architect</span></div>
                    
                          <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                            <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 1</div></div>
                          <div class="col-md-11">
                           
                           <div class="accordion accordion-flush" id="accordionFlushExample">
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingfive">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                                    Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                  </button>
                                </h2>
                                <div id="flush-collapsefive" class="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                                </div>
                              </div>
                             
                            </div>
                          </div></div>
  
  
                          <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                            <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 3</div></div>
                          <div class="col-md-11">
                           
                           <div class="accordion accordion-flush" id="accordionFlushExample">
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingsix">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                                    Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                  </button>
                                </h2>
                                <div id="flush-collapsesix" class="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                                </div>
                              </div>
                             
                            </div>
                          </div></div>
                    
                          <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                            <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 2</div></div>
                          <div class="col-md-11">
                           
                           <div class="accordion accordion-flush" id="accordionFlushExample">
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingseven">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseseven" aria-expanded="false" aria-controls="flush-collapseseven">
                                    Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                  </button>
                                </h2>
                                <div id="flush-collapseseven" class="accordion-collapse collapse" aria-labelledby="flush-headingseven" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                                </div>
                              </div>
                             
                            </div>
                          </div></div>
                    
                          <div class="row mt-3" style=" background-color: #204978; color: white; padding: 10px ; justify-content: center;">
                            <span class="faq-header" style="display: flex;align-items: center;justify-content: center;"> Devops Automation testing</span></div>
                    
                            <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                              <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 1</div> </div>
                            <div class="col-md-11">
                             
                             <div class="accordion accordion-flush" id="accordionFlushExample">
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="flush-headingeight">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseeight" aria-expanded="false" aria-controls="flush-collapseeight">
                                      Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                    </button>
                                  </h2>
                                  <div id="flush-collapseeight" class="accordion-collapse collapse" aria-labelledby="flush-headingeight" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                                  </div>
                                </div>
                               
                              </div>
                            </div></div>
                            <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                              <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 2</div></div>
                            <div class="col-md-11">
                             
                             <div class="accordion accordion-flush" id="accordionFlushExample">
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="flush-headingnine">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsenine" aria-expanded="false" aria-controls="flush-collapsenine">
                                      Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                    </button>
                                  </h2>
                                  <div id="flush-collapsenine" class="accordion-collapse collapse" aria-labelledby="flush-headingnine" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                                  </div>
                                </div>
                               
                              </div>
                            </div></div>
                    
                            <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                              <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 3</div></div>
                            <div class="col-md-11">
                             
                             <div class="accordion accordion-flush" id="accordionFlushExample">
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="flush-headingten">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseten" aria-expanded="false" aria-controls="flush-collapseten">
                                      Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                    </button>
                                  </h2>
                                  <div id="flush-collapseten" class="accordion-collapse collapse" aria-labelledby="flush-heading" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                                  </div>
                                </div>
                               
                              </div>
                            </div></div>
                    
                          </div>
  
  
  
  
                          <div *ngIf="type == 'full-stack'" class="container  mb-5" style="width: 90%">
                            <div class="row header mt-5" style=" background-color: #204978; color: white; padding: 10px ; justify-content: center;">
                              <span class="faq-header" style="display: flex;align-items: center;justify-content: center;">Developer</span></div>
                          
                              <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                                <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 1</div></div>
                              <div class="col-md-11">
                               
                               <div class="accordion accordion-flush" id="accordionFlushExample">
                                  <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingOne">
                                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                      </button>
                                    </h2>
                                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                      <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                                    </div>
                                  </div>
                                 
                                </div>
                              </div></div>
  
  
                              <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                                <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 2</div></div>
                              <div class="col-md-11">
                               
                               <div class="accordion accordion-flush" id="accordionFlushExample">
                                  <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingTwo">
                                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                      </button>
                                    </h2>
                                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                      <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                                    </div>
                                  </div>
                                 
                                </div>
                              </div></div>
                          
                              <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                                <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 3</div></div>
                              <div class="col-md-11">
                               
                               <div class="accordion accordion-flush" id="accordionFlushExample">
                                  <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingthree">
                                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsethree" aria-expanded="false" aria-controls="flush-collapsethree">
                                        Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                      </button>
                                    </h2>
                                    <div id="flush-collapsethree" class="accordion-collapse collapse" aria-labelledby="flush-headingthree" data-bs-parent="#accordionFlushExample">
                                      <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                                    </div>
                                  </div>
                                 
                                </div>
                              </div></div>
                          
                              <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                                <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 4</div></div>
                              <div class="col-md-11">
                               
                               <div class="accordion accordion-flush" id="accordionFlushExample">
                                  <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingfour">
                                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
                                        Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                      </button>
                                    </h2>
                                    <div id="flush-collapsefour" class="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                                      <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                                    </div>
                                  </div>
                                 
                                </div>
                              </div></div>
                          
                          
                              <div class="row mt-3" style=" background-color: #204978; color: white; padding: 10px ; justify-content: center;">
                                <span class="faq-header" style="display: flex;align-items: center;justify-content: center;">Data Analyst</span></div>
                          
                                <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                                  <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 1</div></div>
                                <div class="col-md-11">
                                 
                                 <div class="accordion accordion-flush" id="accordionFlushExample">
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="flush-headingfive">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                                          Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                        </button>
                                      </h2>
                                      <div id="flush-collapsefive" class="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                                      </div>
                                    </div>
                                   
                                  </div>
                                </div></div>
  
  
                                <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                                  <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 3</div></div>
                                <div class="col-md-11">
                                 
                                 <div class="accordion accordion-flush" id="accordionFlushExample">
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="flush-headingsix">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                                          Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                        </button>
                                      </h2>
                                      <div id="flush-collapsesix" class="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                                      </div>
                                    </div>
                                   
                                  </div>
                                </div></div>
                          
                                <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                                  <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 2</div></div>
                                <div class="col-md-11">
                                 
                                 <div class="accordion accordion-flush" id="accordionFlushExample">
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="flush-headingseven">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseseven" aria-expanded="false" aria-controls="flush-collapseseven">
                                          Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                        </button>
                                      </h2>
                                      <div id="flush-collapseseven" class="accordion-collapse collapse" aria-labelledby="flush-headingseven" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                                      </div>
                                    </div>
                                   
                                  </div>
                                </div></div>
                          
                                <div class="row mt-3" style=" background-color: #204978; color: white; padding: 10px ; justify-content: center;">
                                  <span class="faq-header" style="display: flex;align-items: center;justify-content: center;"> Software Developer </span></div>
                          
                                  <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                                    <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 1</div> </div>
                                  <div class="col-md-11">
                                   
                                   <div class="accordion accordion-flush" id="accordionFlushExample">
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="flush-headingeight">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseeight" aria-expanded="false" aria-controls="flush-collapseeight">
                                            Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                          </button>
                                        </h2>
                                        <div id="flush-collapseeight" class="accordion-collapse collapse" aria-labelledby="flush-headingeight" data-bs-parent="#accordionFlushExample">
                                          <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                                        </div>
                                      </div>
                                     
                                    </div>
                                  </div></div>
                                  <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                                    <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 2</div></div>
                                  <div class="col-md-11">
                                   
                                   <div class="accordion accordion-flush" id="accordionFlushExample">
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="flush-headingnine">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsenine" aria-expanded="false" aria-controls="flush-collapsenine">
                                            Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                          </button>
                                        </h2>
                                        <div id="flush-collapsenine" class="accordion-collapse collapse" aria-labelledby="flush-headingnine" data-bs-parent="#accordionFlushExample">
                                          <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                                        </div>
                                      </div>
                                     
                                    </div>
                                  </div></div>
                          
                                  <div class="row mt-3" style="height: 100%; background: white; box-shadow: 0px 4px 4px rgba(197.62, 197.62, 197.62, 0.25); border-radius: 4px; " >
                                    <div class="col-md-1" style="background:#CFDDED; padding: 10px; justify-content: center; color: #204978;"><div class="slno" > 3</div></div>
                                  <div class="col-md-11">
                                   
                                   <div class="accordion accordion-flush" id="accordionFlushExample">
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="flush-headingten">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseten" aria-expanded="false" aria-controls="flush-collapseten">
                                            Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                                          </button>
                                        </h2>
                                        <div id="flush-collapseten" class="accordion-collapse collapse" aria-labelledby="flush-heading" data-bs-parent="#accordionFlushExample">
                                          <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>
                                        </div>
                                      </div>
                                     
                                    </div>
                                  </div></div>
                          
                                </div>
    
  